a {
  text-decoration: none;
}

h1 {
  font-family: Staatliches;
  font-size: 60px;
}

em {
  color: gray !important 
}

#category {
  font-family: Staatliches;
  font-weight: 500;
}

#title {
  letter-spacing: 0.4px;
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.13636;
}

#banner {
  margin: 20px;
  height: 800px;
}

#editor {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.75;
}

.uk-navbar-container {
  background: #fff !important;
  font-family: Staatliches;
}

img:hover {
  opacity: 1;
  transition: opacity 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.img-effect-rotate {
  transform-origin: top left;
  width: auto;
  transform: rotate(90deg);
  margin-right: -50%;
}

.img-effect-gray {
  filter: grayscale(100);
}

.img-effect-sepia {
  filter: sepia(100);
}

.img-effect-invert {
  filter: invert(100);
}

.img-effect-saturate {
  filter: saturate(6);
}

.uk-navbar-container {
  background-color: brown;
}

.uk-navbar-left {
  margin-left: 20px;
}
.uk-navbar-right {
  margin-right: 20px;
}

.zoom-on-hover:hover {
  transition-delay: 0.2s;
  transition-duration: 0.3s;
  transform: scale(1.8);
  z-index: 5;
}

.zoom-on-hover {
  transition-delay: 0.1s;
  transition-duration: 0.2s;
}

.uk-panel-box {
  padding: 15px;
  color: #666;
  background-color: #fafafa;
  border: 1px solid #e5e5e5;
}

.uk-list-line > li:nth-child(n + 2) {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid #e5e5e5;
}

/* Phone landscape and bigger */
@media (min-width: 480px) {
  /* Whole */
  .uk-width-small-1-1 {
    width: 100%;
  }
  /* Halves */
  .uk-width-small-1-2,
  .uk-width-small-2-4,
  .uk-width-small-3-6,
  .uk-width-small-5-10 {
    width: 50%;
  }
  /* Thirds */
  .uk-width-small-1-3,
  .uk-width-small-2-6 {
    width: 33.333%;
  }
  .uk-width-small-2-3,
  .uk-width-small-4-6 {
    width: 66.666%;
  }
  /* Quarters */
  .uk-width-small-1-4 {
    width: 25%;
  }
  .uk-width-small-3-4 {
    width: 75%;
  }
  /* Fifths */
  .uk-width-small-1-5,
  .uk-width-small-2-10 {
    width: 20%;
  }
  .uk-width-small-2-5,
  .uk-width-small-4-10 {
    width: 40%;
  }
  .uk-width-small-3-5,
  .uk-width-small-6-10 {
    width: 60%;
  }
  .uk-width-small-4-5,
  .uk-width-small-8-10 {
    width: 80%;
  }
  /* Sixths */
  .uk-width-small-1-6 {
    width: 16.666%;
  }
  .uk-width-small-5-6 {
    width: 83.333%;
  }
  /* Tenths */
  .uk-width-small-1-10 {
    width: 10%;
  }
  .uk-width-small-3-10 {
    width: 30%;
  }
  .uk-width-small-7-10 {
    width: 70%;
  }
  .uk-width-small-9-10 {
    width: 90%;
  }
}
/* Tablet and bigger */
@media (min-width: 768px) {
  /* Whole */
  .uk-width-medium-1-1 {
    width: 100%;
  }
  /* Halves */
  .uk-width-medium-1-2,
  .uk-width-medium-2-4,
  .uk-width-medium-3-6,
  .uk-width-medium-5-10 {
    width: 50%;
  }
  /* Thirds */
  .uk-width-medium-1-3,
  .uk-width-medium-2-6 {
    width: 33.333%;
  }
  .uk-width-medium-2-3,
  .uk-width-medium-4-6 {
    width: 66.666%;
  }
  /* Quarters */
  .uk-width-medium-1-4 {
    width: 25%;
  }
  .uk-width-medium-3-4 {
    width: 75%;
  }
  /* Fifths */
  .uk-width-medium-1-5,
  .uk-width-medium-2-10 {
    width: 20%;
  }
  .uk-width-medium-2-5,
  .uk-width-medium-4-10 {
    width: 40%;
  }
  .uk-width-medium-3-5,
  .uk-width-medium-6-10 {
    width: 60%;
  }
  .uk-width-medium-4-5,
  .uk-width-medium-8-10 {
    width: 80%;
  }
  /* Sixths */
  .uk-width-medium-1-6 {
    width: 16.666%;
  }
  .uk-width-medium-5-6 {
    width: 83.333%;
  }
  /* Tenths */
  .uk-width-medium-1-10 {
    width: 10%;
  }
  .uk-width-medium-3-10 {
    width: 30%;
  }
  .uk-width-medium-7-10 {
    width: 70%;
  }
  .uk-width-medium-9-10 {
    width: 90%;
  }
}
/* Desktop and bigger */
@media (min-width: 960px) {
  /* Whole */
  .uk-width-large-1-1 {
    width: 100%;
  }
  /* Halves */
  .uk-width-large-1-2,
  .uk-width-large-2-4,
  .uk-width-large-3-6,
  .uk-width-large-5-10 {
    width: 50%;
  }
  /* Thirds */
  .uk-width-large-1-3,
  .uk-width-large-2-6 {
    width: 33.333%;
  }
  .uk-width-large-2-3,
  .uk-width-large-4-6 {
    width: 66.666%;
  }
  /* Quarters */
  .uk-width-large-1-4 {
    width: 25%;
  }
  .uk-width-large-3-4 {
    width: 75%;
  }
  /* Fifths */
  .uk-width-large-1-5,
  .uk-width-large-2-10 {
    width: 20%;
  }
  .uk-width-large-2-5,
  .uk-width-large-4-10 {
    width: 40%;
  }
  .uk-width-large-3-5,
  .uk-width-large-6-10 {
    width: 60%;
  }
  .uk-width-large-4-5,
  .uk-width-large-8-10 {
    width: 80%;
  }
  /* Sixths */
  .uk-width-large-1-6 {
    width: 16.666%;
  }
  .uk-width-large-5-6 {
    width: 83.333%;
  }
  /* Tenths */
  .uk-width-large-1-10 {
    width: 10%;
  }
  .uk-width-large-3-10 {
    width: 30%;
  }
  .uk-width-large-7-10 {
    width: 70%;
  }
  .uk-width-large-9-10 {
    width: 90%;
  }
}
/* Large screen and bigger */
@media (min-width: 1220px) {
  /* Whole */
  .uk-width-xlarge-1-1 {
    width: 100%;
  }
  /* Halves */
  .uk-width-xlarge-1-2,
  .uk-width-xlarge-2-4,
  .uk-width-xlarge-3-6,
  .uk-width-xlarge-5-10 {
    width: 50%;
  }
  /* Thirds */
  .uk-width-xlarge-1-3,
  .uk-width-xlarge-2-6 {
    width: 33.333%;
  }
  .uk-width-xlarge-2-3,
  .uk-width-xlarge-4-6 {
    width: 66.666%;
  }
  /* Quarters */
  .uk-width-xlarge-1-4 {
    width: 25%;
  }
  .uk-width-xlarge-3-4 {
    width: 75%;
  }
  /* Fifths */
  .uk-width-xlarge-1-5,
  .uk-width-xlarge-2-10 {
    width: 20%;
  }
  .uk-width-xlarge-2-5,
  .uk-width-xlarge-4-10 {
    width: 40%;
  }
  .uk-width-xlarge-3-5,
  .uk-width-xlarge-6-10 {
    width: 60%;
  }
  .uk-width-xlarge-4-5,
  .uk-width-xlarge-8-10 {
    width: 80%;
  }
  /* Sixths */
  .uk-width-xlarge-1-6 {
    width: 16.666%;
  }
  .uk-width-xlarge-5-6 {
    width: 83.333%;
  }
  /* Tenths */
  .uk-width-xlarge-1-10 {
    width: 10%;
  }
  .uk-width-xlarge-3-10 {
    width: 30%;
  }
  .uk-width-xlarge-7-10 {
    width: 70%;
  }
  .uk-width-xlarge-9-10 {
    width: 90%;
  }
}

.uk-active {
  font-weight: 700;
}
